import React from 'react';
import './Investors.css';
import AndrewKingston from '../img/AndrewKingston.webp'
import VictorDelgado from '../img/VictorDelgado.webp'
import JohnathanWells from '../img/JohnathanWells.webp'
import EleanorHartman from '../img/EleanorHartman.webp'
import HarutoYamamoto from '../img/HarutoYamamoto.webp'

function Investors() {
  return (
    <div className="investors-page">
      <h1>Welcome to the Future</h1>
      <p>
        DEMIURGE INC. is at the cutting edge of technological innovation, pioneering reality-warping inventions that have the potential to reshape industries across the globe. As we continue to push the boundaries of what is scientifically possible, we invite visionary investors to join us in our journey towards unlocking unprecedented technological breakthroughs. Investing in DEMIURGE INC. means being part of a movement that transcends traditional industries, offering opportunities for growth in fields that are yet to be fully realized.
      </p>

      <h2>Why Invest in DEMIURGE INC.?</h2>
      <ul>
        <li><strong>Pioneering Technology:</strong> Our unique position as leaders in reality-warping research ensures that we remain at the forefront of technological advancement. Our innovations have applications across numerous sectors, including healthcare, defense, economics, entertainment, and more.</li>
        <li><strong>Exponential Growth Potential:</strong> The rapidly expanding market for advanced technologies, coupled with our proprietary research, positions DEMIURGE INC. for significant growth in a multi-billion dollar industry within the coming years.</li>
        <li><strong>Ethical Leadership and Responsibility:</strong> We are committed to conducting our research under the strictest ethical guidelines, ensuring that our innovations benefit humanity (and our investors) as a whole.</li>
        <li><strong>Exclusive Access to Groundbreaking Research:</strong> Investors gain insider access to our latest developments, allowing for strategic involvement in projects with potentially transformative outcomes.</li>
      </ul>

      <h2>Investment Opportunities</h2>
      <ul>
        <li><strong>Equity Investments:</strong> Secure a stake in DEMIURGE INC.'s future by becoming an equity investor. This option is perfect for those looking to align themselves with the long-term growth of the company.</li>
        <li><strong>Partnerships and Collaborations:</strong> We offer unique opportunities for strategic partnerships, allowing investors to collaborate directly with our research teams on specific projects.</li>
        <li><strong>Innovation Fund:</strong> Contribute to our Innovation Fund, which supports the most cutting-edge research initiatives within DEMIURGE INC. This fund is designed for investors looking to make a direct impact on the future of technology.</li>
      </ul>

      <h2>Investor Relations Contact</h2>
      <p>
        If you're interested in learning more about investment opportunities at DEMIURGE INC., please contact our Investor Relations team at <a href="mailto:freakbait@freakbait.com">investorrelations@demiurge-inc.com</a>. Our team is available to discuss your investment goals and provide detailed information on how you can be part of our groundbreaking work.
      </p>

      <h2>Prominent Investors Associated with DEMIURGE INC.</h2>

      <div className="investor-profile">
        <img src={JohnathanWells} alt="Johnathan Wells" />
        <div>
          <h3>Johnathan Wells - Visionary Technologist</h3>
          <p>Johnathan Wells is a renowned figure in the tech industry, known for his early investments in transformative technologies. His investment in DEMIURGE INC. reflects his belief in the future of reality-warping research.</p>
        </div>
      </div>

      <div className="investor-profile">
        <img src={EleanorHartman} alt="Eleanor Hartman" />
        <div>
          <h3>Eleanor Hartman - Strategic Venture Capitalist</h3>
          <p>With a focus on disruptive technologies, Eleanor Hartman has been a key investor in several successful tech startups. Her involvement with DEMIURGE INC. brings strategic insight and extensive experience in scaling innovative companies.</p>
        </div>
      </div>

      <div className="investor-profile">
        <img src={VictorDelgado} alt="Victor Delgado" />
        <div>
          <h3>Victor Delgado - Defense Industry Mogul</h3>
          <p>As a leading figure in the defense sector, Victor Delgado has a keen interest in the potential defense applications of reality-warping technologies. His support for DEMIURGE INC. underscores the broad implications of our research.</p>
        </div>
      </div>

      <div className="investor-profile">
        <img src={AndrewKingston} alt="Andrew Kingston" />
        <div>
          <h3>Andrew Kingston - Philanthropist and Innovator</h3>
          <p>Andrew Kingston is a philanthropist dedicated to advancing scientific research for the betterment of humanity. His investment in DEMIURGE INC. aligns with his mission to support technologies that have the potential to create a positive global impact.</p>
        </div>
      </div>

      <div className="investor-profile">
        <img src={HarutoYamamoto} alt="Haruto Yamamoto" />
        <div>
          <h3>Haruto Yamamoto - International Finance Leader</h3>
          <p>Haruto Yamamoto brings a wealth of knowledge from the world of international finance. His investment in DEMIURGE INC. is part of a broader strategy to support groundbreaking technologies with global reach.</p>
        </div>
      </div>
    </div>
  );
}

export default Investors;
