import React, { useState } from 'react';
import './News.css';

function News() {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const newsItems = [
    {
      title: "September 2024: Demiurge Inc. Unveils Next-Generation Reality Warping Technology",
      content: "Demiurge Inc. is excited to announce the successful development of our latest reality-warping technology, set to revolutionize various industries, including entertainment, defense, and medicine. This breakthrough will allow users to manipulate and experience augmented reality with unprecedented precision and control. The new technology integrates seamlessly with existing systems, making it accessible for widespread adoption across different sectors. Early testing has shown remarkable results, with users reporting an unparalleled level of immersion and interaction."
    },
    {
      title: "August 2024: Demiurge Inc. Launches Cutting-Edge Virtual Reality Training Program",
      content: "Demiurge Inc. has officially launched its groundbreaking Virtual Reality Training Program designed for professionals in high-risk industries such as aerospace, healthcare, and military operations. This innovative program immerses trainees in realistic, high-pressure scenarios, enabling them to develop critical skills in a controlled environment. Our advanced VR simulations are powered by proprietary technology that adapts to the user’s responses, providing a personalized training experience. The program has received acclaim for its potential to revolutionize training methodologies across multiple sectors."
    },
    {
      title: "July 2024: Collaboration with Global Research Institutes",
      content: "In July, Demiurge Inc. entered into strategic partnerships with several leading global research institutions. These collaborations aim to advance our understanding of quantum mechanics and its applications in real-world scenarios. Our joint projects are expected to yield groundbreaking results that will push the boundaries of what is scientifically possible. The collaboration also paves the way for future projects that could redefine our approach to complex scientific challenges."
    },
    {
      title: "June 2024: Expansion of Facilities",
      content: "Demiurge Inc. has expanded its state-of-the-art facilities to accommodate the growing demand for our cutting-edge research. The new laboratories and testing grounds will allow for more comprehensive experimentation and faster development cycles, ensuring we remain at the forefront of technological innovation. This expansion also includes the addition of specialized equipment that will enable our teams to explore new areas of research. Our commitment to providing the best resources for our scientists continues to drive our success and innovation."
    },
    {
      title: "May 2024: Public Lecture Series on Multiverse Theories",
      content: "To foster greater understanding of our work, Demiurge Inc. launched a public lecture series focused on multiverse theories and their practical applications. These lectures are led by our top scientists and are open to the public, providing a rare glimpse into the complex and fascinating world of advanced theoretical physics. Attendance has exceeded expectations, with participants expressing keen interest in the implications of multiverse research on future technology. The series has sparked widespread discussion and interest in the scientific community and beyond."
    },
    {
      title: "April 2024: Launch of the 'Future Vision' Internship Program",
      content: "Demiurge Inc. is proud to introduce the 'Future Vision' Internship Program, offering young scientists and engineers the opportunity to work alongside our experts. This program is designed to cultivate the next generation of innovators, providing them with hands-on experience in cutting-edge research and development. Participants will have the chance to contribute to real-world projects and gain invaluable insights into the future of science and technology. The program has already attracted top talent from universities around the globe, reflecting our commitment to shaping the future of innovation."
    },
    {
      title: "March 2024: Demiurge Inc. Recognized for Ethical Innovation",
      content: "In March 2024, Demiurge Inc. received the prestigious Ethical Innovation Award from the International Science Council. This award highlights our commitment to advancing technology while adhering to the highest ethical standards. Our dedication to responsible research practices sets us apart as a leader in the industry. We continue to prioritize ethical considerations in all our projects, ensuring that our innovations benefit society as a whole."
    },
    {
      title: "February 2024: New Research Grants Secured",
      content: "Demiurge Inc. secured several new research grants totaling over $50 million in February 2024. These funds will support our ongoing projects in quantum computing, artificial intelligence, and next-gen material sciences. The grants will also enable us to explore new avenues of research that could have far-reaching implications for the future. We are committed to using these resources to drive innovation and achieve significant scientific breakthroughs."
    },
    {
      title: "January 2024: Demiurge Inc. Launches 'Vision 2030' Initiative",
      content: "At the start of the year, Demiurge Inc. launched the 'Vision 2030' initiative, a long-term strategic plan aimed at positioning the company as a global leader in reality manipulation technologies. This ambitious roadmap outlines our goals for the next decade, including key milestones in research, development, and commercialization. As part of this initiative, we will be expanding our global presence and increasing our investment in emerging technologies. Our Vision 2030 plan is designed to ensure that Demiurge Inc. remains at the forefront of innovation for years to come."
    },
    {
      title: "December 2023: Record Year for Patent Filings",
      content: "December 2023 marked a record year for Demiurge Inc. with the filing of over 120 patents. These patents cover a wide range of innovations, from advanced neural interfaces to groundbreaking AR applications. This achievement underscores our relentless pursuit of innovation and our commitment to developing cutting-edge technologies. The new patents will play a crucial role in securing our position as a leader in the tech industry and ensuring our continued growth and success."
    },
    {
      title: "November 2023: Strategic Acquisition of Quantum Dynamics Ltd.",
      content: "In November 2023, Demiurge Inc. completed the strategic acquisition of Quantum Dynamics Ltd., a company specializing in quantum entanglement technologies. This acquisition strengthens our position in the quantum technology sector and provides us with the expertise needed to accelerate our research and development efforts. The integration of Quantum Dynamics’ expertise into our operations is already yielding significant advancements in our quantum research projects. We are excited about the potential this acquisition brings to enhance our technological capabilities."
    },
    {
      title: "October 2023: Demiurge Inc. Hosts First Annual Science and Technology Symposium",
      content: "October 2023 saw the inaugural Science and Technology Symposium hosted by Demiurge Inc. The event brought together leading scientists, engineers, and innovators from around the world to discuss the latest advancements in reality manipulation, quantum computing, and artificial intelligence. The symposium was a resounding success and is set to become an annual event. Participants shared groundbreaking ideas and collaborated on potential future projects, setting the stage for the next wave of scientific innovation."
    }
  ];

  return (
    <div className="container">
      {newsItems.map((item, index) => (
        <div
          key={index}
          className={`news-item ${openItem === index ? 'open' : ''}`}
        >
          <h3 className="news-title" onClick={() => toggleItem(index)}>
            {item.title}
          </h3>
          {openItem === index && <p>{item.content}</p>}
        </div>
      ))}
    </div>
  );
}

export default News;
