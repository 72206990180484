import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import PageContent from './components/PageContent';
import Modal from './components/Modal';
import users from './users';
import FO from './img/fo.webp';
import admincctvImages from './data/admincctvImages';

function App() {
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const [activePage, setActivePage] = useState('home');
  const [fullName, setFullName] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarShrunk, setIsSidebarShrunk] = useState(true);
  const [glitch, setGlitch] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({ experiments: [], email: [], files: [], cctvImages: [] });
  const [emailSelected, setEmailSelected] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState('');


  let keys = [];
  let timer;

  const funWord = "5457525048";
  const funWord2 = "1021051009896";

  useEffect(() => {
    const handleKeyDown = (e) => {
      console.log("Key pressed:", e.keyCode);
      keys.push(e.keyCode);
      if (keys.length > 5) {
        keys.shift();
      }

      console.log("Current sequence:", keys.join(''));
      if (keys.join('') === funWord || keys.join('') === funWord2) {
        console.log("Success!");
        setModalContent(`
          <a href="https://freakbait.com" target="_blank" rel="noopener noreferrer">
            <img src="${FO}" alt="Freakbait" style="max-width:50%; height:auto; display: block; margin: 0 auto;" />
          </a>
        `);
        setIsModalOpen(true);
        keys = [];
        clearTimeout(timer);
      }

      clearTimeout(timer);
      timer = setTimeout(() => {
        console.log("Timer expired. Resetting key sequence.");
        keys = [];
      }, 3000);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const startRandomGlitch = () => {
      const randomDelay = Math.floor(Math.random() * 10000) + 6000; 
      const glitchDuration = Math.floor(Math.random() * 1000) + 100; 
      setTimeout(() => {
        setGlitch(true);
        setTimeout(() => {
          setGlitch(false); 
        }, glitchDuration);
        startRandomGlitch(); 
      }, randomDelay);
    };

    if (isModalOpen) {
      startRandomGlitch(); 
    }

    return () => {
      clearTimeout();
    };
  }, [isModalOpen]);

  const toggleSidebar = () => {
    setIsSidebarShrunk(!isSidebarShrunk);
  };

  const loadData = async (path) => {
    try {
      console.log(`Loading data from ./data/${path}Experiments.js`);
      const experimentsModule = await import(`./data/${path}Experiments.js`);
      const emailModule = await import(`./data/${path}Email.js`);
      const filesModule = await import(`./data/${path}Files.js`);

      setCurrentUserData(prevData => ({
        ...prevData,
        experiments: experimentsModule.default || [],
        email: emailModule.default || [],
        files: filesModule.default || [],
        cctvImages: admincctvImages,
      }));
    } catch (error) {
      console.error("Failed to load data for:", path, error);
      setCurrentUserData({ experiments: [], email: [], files: [] });
    }
  };

  const handleNavigation = (page) => {
    setActivePage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (window.innerWidth <= 768) {
      setIsSidebarShrunk(true);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const user = users[inputUsername];
    if (user && inputPassword === user.password) {
      setLoggedIn(true);
      setFullName(user.fullName);
      setCurrentUserRole(user.role);
      setActivePage('user-landing');
      loadData(user.dataPath);
      setError('');

      if (window.innerWidth <= 768) {
        setIsSidebarShrunk(true);
      }
    } else {
      setError('Incorrect username or password');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setActivePage('home');
    setInputUsername('');
    setInputPassword('');
    setCurrentUserData({ experiments: [], email: [] });
  };

  const [currentEmail, setCurrentEmail] = useState(null);
  const handleEmailSelect = (log) => {
    setCurrentEmail(log);
    setEmailSelected(true);
  };

  const handleBackToList = () => {
    setEmailSelected(false);
  };
  

  const pageContent = PageContent(
    setActivePage,
    currentUserData,
    setModalContent,
    setIsModalOpen,
    emailSelected,
    handleEmailSelect,
    currentEmail,
    handleBackToList,
    fullName,
    inputUsername, 
    users[inputUsername]?.role 
  );

  return (
    <div className="App">
      <Header toggleSidebar={toggleSidebar} />
      <div className="menu-bar" onClick={toggleSidebar}>
        <p>Menu</p>
      </div>
      <Sidebar
        isSidebarShrunk={isSidebarShrunk}
        handleNavigation={handleNavigation}
        loggedIn={loggedIn}
        users={users}
        inputUsername={inputUsername}
        setInputUsername={setInputUsername}
        setInputPassword={setInputPassword}
        handleLogout={handleLogout}
        toggleSidebar={toggleSidebar}
        error={error}
        handleLogin={handleLogin}
      />

      <MainContent
        pageContent={pageContent}
        activePage={activePage}
      />

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={modalContent}
        glitch={glitch}
      />
    </div>
  );
}

export default App;
