import React, { useState } from 'react';
import './FAQ.css';

function FAQ() {
  const faqData = [
    {
      question: "What is DEMIURGE INC.?",
      answer: "DEMIURGE INC. is a cutting-edge research facility at the forefront of technological innovation, specializing in reality-warping technologies. Our mission is to challenge the limits of what is scientifically possible by exploring new dimensions of human experience and technology. Through our pioneering research, we aim to unlock the full potential of reality manipulation, pushing boundaries that were once considered purely speculative. Our work spans across various disciplines, and we are committed to advancing knowledge in ways that will fundamentally alter the future of science and technology."
    },
    {
      question: "What exactly are the origins of DEMIURGE INC.?",
      answer: "While official records (i.e. IRS payroll documentation) indicate that the laboratory was founded in 1990, deeper investigations have revealed anomalies suggesting that the lab may have existed long before its formal inception. Some of our earliest research projects reference the company itself—decades before it was officially known or discovered. This paradox has led to theories that DEMIURGE INC. may not have been founded in the traditional sense, but rather discovered, potentially even by itself, through its own experiments on time loops, dimensional shifts, and reality modulation. Some believe that DEMIURGE INC. created its own inception point in a self-referential loop. Whether by design or anomaly, the lab's existence has become deeply intertwined with the very phenomena it studies, and much like the multiverse, its origins remain a mystery we may never fully unravel."
    },
    {
      question: "How can I participate in DEMIURGE INC.'s research projects?",
      answer: "Participation in DEMIURGE INC.’s research projects is highly competitive, given the groundbreaking nature of our work. We conduct a rigorous application process to ensure that only the most qualified and committed individuals are selected. While we occasionally open applications for specific experiments, these opportunities are rare and require applicants to meet strict criteria. If you are passionate about pushing the boundaries of reality and technology, we encourage you to monitor our Careers page for openings. Successful applicants will have the chance to contribute to research that could change the world(s)."
    },
    {
      question: "What kind of experiments does DEMIURGE INC. conduct?",
      answer: "At DEMIURGE INC., our experiments cover a vast range of scientific inquiries, from the practical applications of virtual and augmented reality to more speculative research such as multiverse theory and reality manipulation. Our experts specialize in temporal readjustment, asynchronicity, quantum correction, retrocausation, wave function collapse annulment, paradox resolution/'grandfathering,' dreamscape navigation, oneiric cartography, corporeal untangling, custom spacetime origami, neuromorphic enhancement, omni-resonance attunement, multiverse nexus flux alignment, thalamocortical state rewiring, temporal parallax nullification, corticoresonance synchronization, causal re-sequencing, reality synthesis, inter-spatial tethering, and so much more!"
    },
    {
      question: "Is DEMIURGE INC.'s technology safe?",
      answer: "Safety is a paramount concern at DEMIURGE INC., and all of our research is conducted under the strictest ethical guidelines and safety protocols. We understand that our work involves pushing the boundaries of conventional science, which inherently comes with risks. However, we take every possible precaution to ensure that our experiments are conducted in a controlled and secure environment. Participants in our research are fully informed of any potential risks and must provide informed consent before involvement. While the nature of our work means we are often in uncharted territory, we are committed to ensuring the safety and well-being of all participants and researchers."
    },
    {
      question: "Can the public visit DEMIURGE INC.'s facilities?",
      answer: "Due to the sensitive and confidential nature of our work, DEMIURGE INC.'s facilities are not open to the public. Our research involves cutting-edge technologies and proprietary methodologies that require a secure environment. However, we recognize the public's interest in our work, and to that end, we offer information on our website as well as direct inquiry through our Contact page. We remain committed to transparency and public engagement, within the bounds of our security protocols."
    },
    {
      question: "Who can I contact for more information?",
      answer: "If you have any questions or need further information about DEMIURGE INC. and our research, we encourage you to reach out to us through the Contact page. Our team is dedicated to providing clear and helpful information to all inquiries. Whether you are interested in learning more about our experiments, applying for a position, or investing in our work, our team is here to assist you. We strive to respond to all messages promptly and ensure that you have the information you need to engage with DEMIURGE INC."
    },
    {
      question: "Does DEMIURGE INC. collaborate with government agencies?",
      answer: (
        <span>
          DEMIURGE INC. engages in collaborative research with various government agencies worldwide when it aligns with our mission to advance scientific knowledge and technological innovation. These partnerships allow us to leverage additional resources (read: COLD HARD CASH) and expertise, enabling us to explore new frontiers in our research. We maintain strict confidentiality and ethical standards in all collaborations. Some of the agencies we have interacted with include:
          <ul>
            <li>CIA (Central Intelligence Agency, USA)</li>
            <li>FBI (Federal Bureau of Investigation, USA)</li>
            <li>NSA (National Security Agency, USA)</li>
            <li>ATF (Bureau of Alcohol, Tobacco, Firearms and Explosives, USA)</li>
            <li>DIA (Defense Intelligence Agency, USA)</li>
            <li>DHS (Department of Homeland Security, USA)</li>
            <li>MI6 (Secret Intelligence Service, UK)</li>
            <li>GCHQ (Government Communications Headquarters, UK)</li>
            <li>Mossad (Institute for Intelligence and Special Operations, Israel)</li>
            <li>GRU (Main Intelligence Directorate, Russia)</li>
            <li>FSB (Federal Security Service, Russia)</li>
            <li>BND (Federal Intelligence Service, Germany)</li>
            <li>DGI (Directorate of Military Intelligence, France)</li>
            <li>DGSE (Directorate General for External Security, France)</li>
            <li>ASIS (Australian Secret Intelligence Service, Australia)</li>
            <li>CSIS (Canadian Security Intelligence Service, Canada)</li>
            <li>RAW (Research and Analysis Wing, India)</li>
            <li>CNI (National Intelligence Center, Spain)</li>
            <li>SIS (Secret Intelligence Service, New Zealand)</li>
            <li>SBU (Security Service of Ukraine, Ukraine)</li>
          </ul>
          We conduct all collaborations with the highest level of integrity, and our commitment to transparency and ethical responsibility remains paramount in all our dealings.
        </span>
      )
    },
    {
      question: "Is DEMIURGE INC. involved with weapons manufacturers?",
      answer: "DEMIURGE INC. is deeply involved and associated with the development of many cutting-edge technologies, including new and unprecedented weapons technologies. Our research has led to innovations that have captured the attention of the defense industry, and we have collaborated with dozens of weapons manufacturers over the years. While our primary focus remains on reality-warping technologies, we recognize the potential dual-use applications of our work, including in the realm of advanced weaponry. These collaborations are conducted with strict oversight to ensure that all developments are in line with ethical guidelines and contribute to global security in a responsible manner."
    },
    {
      question: "What is DEMIURGE INC.'s response to the controversies surrounding missing persons involved in your experiments?",
      answer: "DEMIURGE INC. is aware of the rumors and speculation regarding missing persons allegedly connected to our research. We want to assure the public that these claims are baseless and without merit. All participants in our experiments are carefully screened, and their participation is fully voluntary and conducted under strict ethical guidelines. We have no involvement in any cases of missing persons, and we cooperate fully with law enforcement agencies to ensure transparency. Our primary focus remains on pioneering safe and responsible technological advancements that benefit humanity."
    },
    {
      question: "What exactly does 'reality modulation' entail?",
      answer: "Reality modulation is a highly specialized field that allows us to manipulate the fabric of existence itself. While this may sound alarming, rest assured, our experiments are all conducted within controlled parameters (most of the time). By adjusting various constants in our known universe, we can influence everything from time perception to physical laws. For instance, reality modulation has allowed us to 'stretch' time during certain high-priority projects. Of course, any reports of missing hours, days, or even weeks for certain staff members are purely coincidental."
    },
    {
      question: "Have any ethical concerns been raised about your work?",
      answer: "Ethics are a cornerstone of everything we do here at DEMIURGE INC. (according to our PR team). We strictly adhere to ethical guidelines, ensuring that all of our projects are as safe and non-invasive as possible. That being said, there have been the occasional 'hiccup'—such as when a project briefly caused local temporal distortions—but we can confidently say that most affected individuals have returned to their normal timelines with little more than a mild headache or sudden loss of memory."
    },
    {
      question: "Can your research be used to create new universes?",
      answer: "In theory, yes. Our research in reality manipulation and dimensional tuning has allowed us to explore the possibility of creating entirely new universes. Of course, these universes are small and highly unstable, and any mention of rogue realities breaking free and attempting to overwrite our own are grossly exaggerated. We’re currently working on methods to contain these universes safely, though we strongly advise against anyone attempting to interact with them until further notice. If you see something strange, say something."
    },
    {
      question: "What happens to participants who fail experiments?",
      answer: "Failure is a part of progress. Participants who 'fail' in our experiments are not so much failures as they are part of a learning process. Of course, in the rare instances when an experiment doesn’t go as planned, participants are swiftly reassigned (and occasionally re-materialized) with little to no permanent damage—aside from the occasional spatial dislocation or loss of corporeal form."
    },
    {
      question: "Have there been any unusual side effects from working at DEMIURGE INC.?",
      answer: "Unusual? Well, nothing out of the ordinary for cutting-edge research in dimensional tuning and reality modulation. Some employees have reported mild side effects such as spontaneous time dilation, temporary non-existence, or waking up in alternate dimensions. But overall, morale remains high! After all, who wouldn’t want to come to work knowing that they might accidentally glimpse the end of the universe or be erased from history?"
    },
    {
      question: "What is the purpose of the room marked 'DO NOT ENTER' in your facility?",
      answer: "Ah, the infamous 'DO NOT ENTER' room. It's less of a place to be feared and more of a... containment area for certain experiments that went *slightly* off course. Rest assured, it's perfectly safe - as long as you DO NOT ENTER. Any rumors you may have heard about strange noises, flashing lights, or shadowy figures seen near the door are simply the result of environmental anomalies. We've handled worse, and we always find a solution. Eventually."
    },
    {
      question: "Why do some staff seem to disappear from company records after certain projects?",
      answer: "We value the privacy of our staff and their involvement in sensitive projects. Occasionally, certain individuals may choose to 'step away' from the company after particularly successful or classified research. Any gaps in company records are purely administrative, and we assure you that all former staff members are accounted for—somewhere, in some dimension, existing happily ever after. Probably living on an old farm or something."
    },
    {
      question: "Are there any forbidden experiments at DEMIURGE INC.?",
      answer: "Officially, no. Unofficially... let's just say that there are certain areas of study we strongly discourage. Some doors are best left unopened, and certain experiments have been placed on indefinite hold due to their 'unpredictable' nature. For instance, there was one project that ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮ we’re still studying the aftereffects. We’re confident that nothing catastrophic will come of it. Probably."
    },

  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <strong>{faq.question}</strong>
          </div>
          {activeIndex === index && (
            <div className="faq-answer">
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default FAQ;
