const users = {
  admin: {
      password: process.env.REACT_APP_USER6_PASSWORD,
      pages: ['log', 'files', 'cctv'],
      dataPath: 'admin',
      fullName: 'Admin',
      role: "admin"
  },
  nmanhav: {
      password: process.env.REACT_APP_USER1_PASSWORD,
      pages: ['log', 'email', 'files'],
      dataPath: 'nmanhav',
      fullName: 'Dr. Manhav',
      role: "researcher"
  },
  estrauss: {
      password: process.env.REACT_APP_USER2_PASSWORD,
      pages: ['log', 'email', 'files'],
      dataPath: 'estrauss',
      fullName: 'Dr. Strauss',
      role: "researcher"
  },
  rbaker: {
      password: process.env.REACT_APP_USER3_PASSWORD,
      pages: ['log', 'email', 'files'],
      dataPath: 'rbaker',
      fullName: 'Dr. Baker',
      role: "researcher"
  },
  across: {
      password: process.env.REACT_APP_USER4_PASSWORD,
      pages: ['log', 'email', 'files'],
      dataPath: 'across',
      fullName: 'Dr. Cross',
      role: "researcher"
  },
  ovale: {
      password: process.env.REACT_APP_USER5_PASSWORD,
      pages: ['log', 'email', 'files'],
      dataPath: 'ovale',
      fullName: 'Dr. Vale',
      role: "researcher"
  }
};

export default users;
