import React from 'react';
import './Home.css';
import logo from '../img/demiurgeLogo.webp'

function Home() {
  return (
    <div className="home-container">
    <img src={logo} alt="Demiurge Inc. Logo" className="logo" />
      <h1>Welcome to DEMIURGE INC.</h1>
      <p>
        At DEMIURGE INC., we are pioneers in reality-warping technology, dedicated to pushing the boundaries of what is scientifically possible. Our innovative solutions are at the forefront of technological advancements, enabling new dimensions of human experience and interaction.
      </p>
      
      <div className="features">
        <div className="feature">
          <h2>Cutting-Edge Research</h2>
          <p>
            Our team of world-renowned scientists and engineers are exploring the most advanced technologies, from quantum computing to augmented reality, to reshape the future of humanity and benefit our investors.
          </p>
        </div>
        <div className="feature">
          <h2>Global Collaboration</h2>
          <p>
            Partnering with leading research institutions and corporations worldwide, we are expanding the horizons of technology, science, and industry through innovative collaborations with the forefront of governments, telecommunications, aerospace, and weapons manufacturers.
          </p>
        </div>
        <div className="feature">
          <h2>Innovative Solutions</h2>
          <p>
            Our cutting-edge solutions address some of the most pressing challenges in multiple industries, including healthcare, defense, and entertainment, providing unparalleled benefits and opportunities. DEMIURGE INC. provides services and abilities of which no other laboratory on the planet is capable.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
