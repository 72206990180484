import cctv1 from '../data/img/offfline.webp';
import cctv2 from '../data/img/offfline2.webp';
import cctv3 from '../data/img/cctvlab3.webp';
import cctv4 from '../data/img/cctvlab4.webp';
import cctv5 from '../data/img/offfline3.webp';
import cctv6 from '../data/img/cctvlab6.webp';
import cctv7 from '../data/img/offfline4.webp';
import cctv8 from '../data/img/cctvlab8.webp';
import cctv9 from '../data/img/CCTVLAB9.webp';

const admincctvImages = [
    { file: cctv1, title: 'CCTV 1' },
    { file: cctv2, title: 'CCTV 2' },
    { file: cctv3, title: 'CCTV 3' },
    { file: cctv4, title: 'CCTV 4' },
    { file: cctv5, title: 'CCTV 5' },
    { file: cctv6, title: 'CCTV 6' },
    { file: cctv7, title: 'CCTV 7' },
    { file: cctv8, title: 'CCTV 8' },
    { file: cctv9, title: 'CCTV 9' }
  ];
export default admincctvImages;